var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LinearProgress, styled } from '@mui/material';
import { themeLevel4 } from '../config/theme';
import { Tooltip } from './tooltip';
var StyledProgressBar = styled(LinearProgress)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        height: theme.typography.pxToRem(50),
        borderRadius: theme.typography.pxToRem(theme.shape.borderRadius / 2),
        backgroundColor: '#5e5e5e',
        '& .MuiLinearProgress-bar': {
            borderRadius: theme.typography.pxToRem(theme.shape.borderRadius / 2),
            boxShadow: theme.shadows[4],
        },
    });
});
var ProgressBarWithoutToolTip = styled(StyledProgressBar)(function (_a) {
    var theme = _a.theme, value = _a.value;
    return ({
        '&::after': {
            position: 'absolute',
            content: "\"".concat(value, "%\""),
            lineHeight: '100%',
            color: theme.palette.text.primary,
            fontSize: theme.typography.h1.fontSize,
            top: '50%',
            left: theme.typography.pxToRem(18),
            transform: 'translateY(-50%)',
        },
    });
});
var ProgressBarWithToolTip = styled(StyledProgressBar)(function () { return ({
    borderRadius: 'unset',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& .MuiLinearProgress-bar': {
        borderRadius: 'unset',
    },
}); });
export var ProgressBar = function (_a) {
    var value = _a.value, className = _a.className, _b = _a.withToolTip, withToolTip = _b === void 0 ? false : _b, tooltipTitle = _a.tooltipTitle;
    if (withToolTip) {
        return (_jsx(Tooltip, __assign({ title: tooltipTitle, placement: "top", disableInteractive: true, componentsProps: {
                tooltip: {
                    sx: {
                        fontWeight: 'bold',
                        maxWidth: 'unset',
                        padding: themeLevel4.spacing(2.5),
                        borderRadius: themeLevel4.spacing(2.5),
                        marginBottom: '0.5rem !important',
                    },
                },
            } }, { children: _jsx(ProgressBarWithToolTip, { "data-testid": "progress-bar-with-tooltip", variant: "determinate", value: Math.round(value !== null && value !== void 0 ? value : 0), className: className }) })));
    }
    return (_jsx(ProgressBarWithoutToolTip, { "data-testid": "progress-bar-without-tooltip", variant: "determinate", value: Math.round(value !== null && value !== void 0 ? value : 0), className: className }));
};
