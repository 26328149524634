import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import * as yup from 'yup';
import { useForm } from '../../hooks';
import { CommonDateRangeFields } from './commonfields';
export var DateRangePickerForm = withSuspense()(function (props) {
    var _a, _b;
    var t = useTranslation().t;
    var _c = useForm(useMemo(function () { return ({
        initialValues: {
            fromDate: props.fromDate,
            toDate: props.toDate,
        },
        validationSchema: yup.object({
            fromDate: yup.date().nullable().required('error.required'),
            toDate: yup.date().nullable().required('error.required'),
        }),
    }); }, [props.fromDate, props.toDate])), values = _c.values, setFieldValue = _c.setFieldValue, getError = _c.getError, handleSubmit = _c.handleSubmit, handleReset = _c.handleReset;
    var handleOnQuickSelect = useCallback(function (_a) {
        var start = _a[0], end = _a[1];
        setFieldValue(start, 'fromDate');
        setFieldValue(end, 'toDate');
    }, [setFieldValue]);
    var onSubmit = handleSubmit(function (validatedValues) {
        props.onSubmit([moment(validatedValues.fromDate), moment(validatedValues.toDate)]);
    });
    return (_jsx(CommonDateRangeFields, { fromDate: values.fromDate, toDate: values.toDate, maxDate: props.maxDate, minDate: props.minDate, minStartDate: props.minStartDate, setFieldValue: setFieldValue, helperTextFromDate: (_a = t(getError('fromDate'), { ns: 'translation' })) !== null && _a !== void 0 ? _a : '', helperTextToDate: (_b = t(getError('toDate'), { ns: 'translation' })) !== null && _b !== void 0 ? _b : '', handleOnQuickSelect: handleOnQuickSelect, onSubmit: onSubmit, onCancel: function () {
            handleReset();
            props.onCancel();
        }, popoverProps: props.popoverProps, actionButtonDirection: props.actionButtonDirection }));
});
