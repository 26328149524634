import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { Autocomplete, AutocompleteProps } from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageTitleState } from '../../state/app';
import { usePageTitleAPI } from './selectors';

const PAGE_TITLE_UNSPECIFIED_VALUE = 'unspecified';

interface PageTitleSelectProps extends AutocompleteProps<false> {
  direction?: 'row' | 'column';
  titleStyle?: SxProps;
  showTitle?: boolean;
  enableAutoHide?: boolean;
}

export const PageTitleSelect = ({
  direction,
  showTitle = true,
  enableAutoHide = true,
  titleStyle,
  ...props
}: PageTitleSelectProps) => {
  const { t } = useTranslation();
  const [pageTitleData] = usePageTitleAPI();
  const [pageTitle, setPageTitle] = usePageTitleState();

  useEffect(() => {
    if (pageTitleData.data && pageTitleData.data.indexOf(pageTitle ?? '') === -1) {
      setPageTitle(pageTitleData.data[0]);
    }
  }, [pageTitle, pageTitleData.data, setPageTitle]);

  const pageOptions = useMemo(
    () =>
      pageTitleData.data?.map((name) => ({
        id: name,
        name,
      })),
    [pageTitleData.data]
  );

  if (enableAutoHide && (pageTitleData.data?.length ?? 0) < 2) return null;
  return (
    <Stack
      flex={1}
      direction={direction}
      alignItems={direction === 'row' ? 'center' : 'stretch'}
      gap={1.5}
      height="100%"
      width={(theme) => (direction === 'row' ? theme.typography.pxToRem(350) : 'unset')}
      sx={props.sx}
    >
      {showTitle && (
        <Typography
          data-testid="title"
          color={(theme) => (props.disabled ? theme.palette.text.disabled : theme.palette.text.primary)}
          whiteSpace="nowrap"
          sx={titleStyle}
        >
          {t('pageTitle.title')}
        </Typography>
      )}

      <Box flex={1}>
        <Autocomplete
          fullWidth
          multiple={false}
          options={pageOptions}
          getOptionLabel={({ name }) => (name === PAGE_TITLE_UNSPECIFIED_VALUE ? t('pageTitle.unspecified') : name)}
          onChange={(e) => setPageTitle(e.target.value)}
          value={pageTitle}
          popupIcon={<FontAwesomeIcon fontSize="1.125rem" icon={['fal', 'chevron-down']} />}
          {...props}
        />
      </Box>
    </Stack>
  );
};
