var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
export var useDebounce = function (callback, depends, delay, triggerOnMOunt) {
    if (delay === void 0) { delay = 0; }
    if (triggerOnMOunt === void 0) { triggerOnMOunt = false; }
    var _a = useState(false), isPending = _a[0], setIsPending = _a[1];
    var mountedRef = useRef(false);
    var unmountedRef = useRef(false);
    useEffect(function () {
        unmountedRef.current = false;
        return function () {
            unmountedRef.current = true;
        };
    }, []);
    useEffect(function () {
        setIsPending(true);
        var unmountHandler;
        var timeoutId;
        var handler = function () {
            unmountHandler = callback();
            setIsPending(false);
        };
        if (triggerOnMOunt && !mountedRef.current) {
            handler();
        }
        else {
            timeoutId = setTimeout(function () {
                if (!unmountedRef.current) {
                    handler();
                }
            }, delay);
        }
        mountedRef.current = true;
        return function () {
            clearTimeout(timeoutId);
            unmountHandler && unmountHandler();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([delay], depends, true));
    return isPending;
};
export var useDebounceValue = function (callback, depends, delay) {
    if (delay === void 0) { delay = 0; }
    var _a = useState(callback), value = _a[0], setValue = _a[1];
    var _b = useState(false), isPending = _b[0], setIsPending = _b[1];
    var mountedRef = useRef(false);
    var unmountedRef = useRef(false);
    useEffect(function () {
        unmountedRef.current = false;
        return function () {
            unmountedRef.current = true;
        };
    }, []);
    useEffect(function () {
        if (mountedRef.current) {
            setIsPending(true);
            var handler_1 = setTimeout(function () {
                if (!unmountedRef.current) {
                    setValue(callback());
                    setIsPending(false);
                }
            }, delay);
            return function () {
                clearTimeout(handler_1);
            };
        }
        mountedRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([delay], depends, true));
    return [value, isPending];
};
export var useDebouncedCallback = function (cb, depends, delay) {
    if (delay === void 0) { delay = 0; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(_.debounce(cb, delay), depends);
};
