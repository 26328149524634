var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, IconButton, TableBody, TableContainer, TableHead, Table as TableMUI, TableRow, styled, } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import _ from 'lodash';
import React, { useState } from 'react';
import { themeLevel2 } from '../../config/theme';
import { Menu } from '../menupopover';
import { EnhancedTableHead } from './enhancedtablehead';
import { getComparator } from './utils';
var StyledTableCell = styled(TableCell)(function (_a) {
    var _b;
    var theme = _a.theme, padding = _a.padding;
    return (_b = {
            padding: padding !== null && padding !== void 0 ? padding : "".concat(theme.typography.pxToRem(12), " ").concat(theme.typography.pxToRem(16))
        },
        _b["&.".concat(tableCellClasses.head)] = {
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
            fontSize: theme.typography.body2.fontSize,
        },
        _b["&.".concat(tableCellClasses.body)] = {
            fontSize: theme.typography.body2.fontSize,
            border: 0,
        },
        _b);
});
var StyledBreakCell = styled(TableCell)(function (_a) {
    var theme = _a.theme;
    return ({
        border: 'none !important',
        backgroundColor: theme.palette.background.default,
    });
});
var StyledTableRow = styled(TableRow)(function (_a) {
    var theme = _a.theme, selected = _a.selected;
    return (__assign({ '&:nth-of-type(even)': {
            backgroundColor: themeLevel2.palette.background.default,
        }, '&:nth-of-type(even) td': {
            color: themeLevel2.palette.text.primary,
        } }, (selected
        ? {
            backgroundColor: "".concat(theme.palette.success.main, " !important"),
            '& > td': { color: "".concat(themeLevel2.palette.background.default, " !important") },
        }
        : {})));
});
var generalRow = function (data, column, index, indexColumn) {
    if (column.breakColumn) {
        return _jsx(StyledBreakCell, { sx: { width: column.width, maxWidth: column.maxWidth } }, indexColumn);
    }
    return (_jsx(StyledTableCell, __assign({ align: column.align }, column.cellProps, { sx: { width: column.width, maxWidth: column.maxWidth } }, { children: column.render ? column.render(data[column.dataIndex], data, index) : _.get(data, column.dataIndex) }), indexColumn));
};
var generalHead = function (column, index, numSelected, rowCount, onSelectAllClick) {
    if (column.breakColumn) {
        return (_jsx(StyledBreakCell, { "data-testid": "break-cell", sx: { width: column.width, maxWidth: column.maxWidth } }, 'break_column'));
    }
    if (column.isCheckBoxAll) {
        return (_jsxs(StyledTableCell, __assign({ "data-testid": "checkbox-all", align: column.align }, column.cellProps, { sx: { width: column.width, maxWidth: column.maxWidth }, padding: "checkbox" }, { children: [_jsx(Checkbox, { color: "primary", indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: onSelectAllClick, inputProps: {
                        'aria-label': 'select all desserts',
                    } }), column.title] }), index));
    }
    return (_jsx(StyledTableCell, __assign({ align: column.align }, column.cellProps, { sx: { width: column.width, maxWidth: column.maxWidth } }, { children: column.title }), index));
};
var TableHeader = function (_a) {
    var columns = _a.columns, onSelectAllClick = _a.onSelectAllClick, _b = _a.numSelected, numSelected = _b === void 0 ? 0 : _b, _c = _a.rowCount, rowCount = _c === void 0 ? 0 : _c;
    return (_jsx(TableHead, { children: _jsx(TableRow, { children: columns.map(function (column, index) { return generalHead(column, index, numSelected, rowCount, onSelectAllClick); }) }, 'header_row') }));
};
var TableDataSource = function (_a) {
    var dataSources = _a.dataSources, selectedRow = _a.selectedRow, columns = _a.columns, onRowClick = _a.onRowClick, _b = _a.getRowId, getRowId = _b === void 0 ? function (row) { return row.id; } : _b;
    return (_jsx(React.Fragment, { children: dataSources === null || dataSources === void 0 ? void 0 : dataSources.map(function (data, index) { return (_jsx(StyledTableRow, __assign({ onClick: function () { return onRowClick === null || onRowClick === void 0 ? void 0 : onRowClick(data, index); }, selected: data === selectedRow }, { children: columns.map(function (column, indexColumn) { return generalRow(data, column, index, indexColumn); }) }), getRowId(data))); }) }));
};
export var Table = function (_a) {
    var _b = _a.sortable, sortable = _b === void 0 ? false : _b, columns = _a.columns, dataSources = _a.dataSources, tableContainerProps = _a.tableContainerProps, tableProps = _a.tableProps, selectedRow = _a.selectedRow, onSelectAllClick = _a.onSelectAllClick, onRowClick = _a.onRowClick, numSelected = _a.numSelected, rowCount = _a.rowCount, getRowId = _a.getRowId, _c = _a.sortMode, sortMode = _c === void 0 ? 'default' : _c, onSort = _a.onSort, props = __rest(_a, ["sortable", "columns", "dataSources", "tableContainerProps", "tableProps", "selectedRow", "onSelectAllClick", "onRowClick", "numSelected", "rowCount", "getRowId", "sortMode", "onSort"]);
    var _d = React.useState((props === null || props === void 0 ? void 0 : props.order) ? props.order : 'asc'), order = _d[0], setOrder = _d[1];
    var _e = React.useState(props === null || props === void 0 ? void 0 : props.orderBy), orderBy = _e[0], setOrderBy = _e[1];
    var handleRequestSort = function (event, property) {
        var isAsc = orderBy === property && order === 'asc';
        var newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        onSort === null || onSort === void 0 ? void 0 : onSort(property, newOrder);
    };
    var visibleRows = React.useMemo(function () {
        var _a;
        if (sortable && sortMode === 'default') {
            var sortFunc = (_a = columns.find(function (column) { return column.sortKey === orderBy; })) === null || _a === void 0 ? void 0 : _a.sortFunc;
            return dataSources === null || dataSources === void 0 ? void 0 : dataSources.slice().sort(getComparator(order, orderBy, sortFunc));
        }
        return dataSources;
    }, [order, orderBy, dataSources, sortable, columns, sortMode]);
    return (_jsx(TableContainer, __assign({ component: Box }, tableContainerProps, { sx: __assign({ position: 'relative' }, tableContainerProps === null || tableContainerProps === void 0 ? void 0 : tableContainerProps.sx) }, { children: _jsxs(TableMUI, __assign({}, tableProps, { children: [sortable ? (_jsx(EnhancedTableHead, { columns: columns, order: order, orderBy: orderBy, onRequestSort: handleRequestSort })) : (_jsx(TableHeader, { columns: columns, onSelectAllClick: onSelectAllClick, numSelected: numSelected, rowCount: rowCount })), _jsx(TableBody, { children: _jsx(TableDataSource, { columns: columns, dataSources: visibleRows, selectedRow: selectedRow, onRowClick: onRowClick, getRowId: getRowId }) })] })) })));
};
var SButton = styled(IconButton)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(0.875),
        border: "1px solid",
    });
});
export var RowMenu = function (props) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        props.onOpen && props.onOpen();
        event.stopPropagation();
    };
    var handleClose = function (event) {
        setAnchorEl(null);
        props.onClose && props.onClose();
        event.stopPropagation();
    };
    return (_jsxs(_Fragment, { children: [_jsx(SButton, __assign({ onClick: handleClick, color: "inherit" }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.125rem", icon: ['fal', 'ellipsis'] }) })), _jsx(Menu, __assign({ anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, onClick: handleClose, keepMounted: props.keepMounted }, { children: props.children }))] }));
};
