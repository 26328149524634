export var PHONE_FORMAT_REGEX = /^(\d{10})?$/;
export var ZIP_CODE_FORMAT_REGEX = /^(\d{5}(\d{4})?)?$/;
export var NO_SPECIAL_CHARACTER_REGEX = /^(?=.*[a-zA-Z])[A-Za-z0-9\s]+$/;
export var APP_VERSION = process.env.REACT_APP_VERSION;
export var LOGOUT_URL = "/api/auth/cognito/logout";
export var customerSpecificCodes = "<script async src=\"https://tag.krateo.ai/ldc.js?pid={cleanName}&aid=081b0267\"></script>\n\n<!-- The following script can be used to inject custom pass-through data, uncomment and populate the `pageInfo` variable to send customized data\n<script>\n    try {\n        /*\n         * The following are the pageInfo attributes and their usage:\n         *  - productId - the id representation of the product the customer is viewing on the page\n         *  - pageTitle - the title of the page which the customer is viewing\n         *  - campaignId - the id of the campaign which this customer came from\n         * Remove unused attributes or pass `undefined` values if not available\n         */\n        const pageInfo = {\n            productId: \"sku12345\",\n            pageTitle: \"Product ABC\",\n            campaignId: \"0a12cf56\"\n        };\n        const pageInfoString = JSON.stringify(pageInfo);\n        if (pageInfoString !== \"{}\"){\n            window.ldcDataLayer = window.ldcDataLayer || [];\n            function ldcConfig(){ldcDataLayer.push(arguments);}\n            ldcConfig('externalId', pageInfoString);\n        }\n    } catch (exception) { }\n</script>\n-->";
export var customerStandardCodes = "<script async src=\"https://tag.krateo.ai/ldc.js?pid={cleanName}&aid=081b0267\"></script>";
export var PortalPageName = {
    campaign360: 'campaign360',
    visitor360: 'visitor360',
    accountManagementPage: 'accountManagement',
    faqPage: 'faq',
    audienceExport: 'audienceExport',
    audiencesPage: 'audiences',
};
export var ACCOUNT_MANAGEMENT_TAB_INDEX = {
    USERS: '1',
    CONNECTIONS: '2',
};
export * from './audit';
export * from './ga';
