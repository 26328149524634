import { createTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
var defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    spacing: function (value) { return "".concat(value * 0.25, "rem"); },
    shape: {
        borderRadius: 10,
    },
    typography: function () { return ({
        fontFamily: 'Helvetica',
        pxToRem: function (px) { return "".concat(px / 16, "rem"); },
        fontWeightRegular: 300,
        fontWeightMedium: 400,
    }); },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    colorScheme: 'dark',
                },
            },
        },
    },
});
var createColor = function (mainColor) { return defaultTheme.palette.augmentColor({ color: { main: mainColor } }); };
export var baseTheme = createTheme(defaultTheme, {
    palette: {
        text: {
            primary: '#ffffffde',
            disabled: '#ffffffad',
        },
        action: {
            active: '#e0e0e0',
            disabled: '#b3b3b3',
        },
        primary: {
            main: '#62b5e5',
            light: '#6797ce',
            dark: '#a6e1fade',
            contrastText: '#e7eef3',
        },
        secondary: {
            main: '#bb006bde',
            dark: '#c33787de',
            contrastText: '#e0e0e0',
        },
        success: {
            main: '#a3ffeede',
            light: '#7cc2a7',
            dark: '#a6e1fade',
        },
        divider: '#b3b3b3',
    },
    typography: {
        h1: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: defaultTheme.typography.pxToRem(40),
            fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        h2: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: defaultTheme.typography.pxToRem(36),
            fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        h3: {
            fontSize: defaultTheme.typography.pxToRem(40),
        },
        h4: {
            fontSize: defaultTheme.typography.pxToRem(36),
            fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        h6: {
            fontSize: defaultTheme.typography.pxToRem(20),
            fontWeight: defaultTheme.typography.fontWeightRegular,
        },
        subtitle1: {
            fontSize: defaultTheme.typography.pxToRem(18),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        subtitle2: {
            fontSize: defaultTheme.typography.pxToRem(16),
            fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        subtitle3: {},
        subtitle4: {},
        body1: {
            fontSize: defaultTheme.typography.pxToRem(18),
        },
        body2: {
            fontSize: defaultTheme.typography.pxToRem(16),
        },
        body3: {
            fontSize: defaultTheme.typography.pxToRem(14),
        },
        body4: {
            fontSize: defaultTheme.typography.pxToRem(12),
        },
        caption: {
            fontSize: defaultTheme.typography.pxToRem(10),
        },
        button: {
            textTransform: 'unset',
            fontSize: defaultTheme.typography.pxToRem(16),
        },
    },
    shadows: [
        'none',
        'none',
        "0 ".concat(defaultTheme.typography.pxToRem(4), " ").concat(defaultTheme.typography.pxToRem(4), " rgba(0, 0, 0, 0.25)"),
        "-".concat(defaultTheme.typography.pxToRem(4), " 0 ").concat(defaultTheme.typography.pxToRem(4), " rgba(0, 0, 0, 0.25)"),
        "".concat(defaultTheme.typography.pxToRem(4), " 0 ").concat(defaultTheme.typography.pxToRem(4), " rgba(0, 0, 0, 0.25)"),
        "".concat(defaultTheme.typography.pxToRem(4), " ").concat(defaultTheme.typography.pxToRem(-4), " ").concat(defaultTheme.typography.pxToRem(4), " rgba(0, 0, 0, 0.25)"),
        'none',
        'none',
        "".concat(defaultTheme.typography.pxToRem(4), " ").concat(defaultTheme.typography.pxToRem(4), " ").concat(defaultTheme.typography.pxToRem(4), " rgba(0, 0, 0, 0.25)"),
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                color: 'success',
            },
            styleOverrides: {
                sizeLarge: {
                    fontSize: defaultTheme.typography.pxToRem(18),
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: defaultTheme.typography.pxToRem(16),
                    marginBottom: defaultTheme.typography.pxToRem(4),
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: defaultTheme.typography.pxToRem(defaultTheme.shape.borderRadius / 2),
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: defaultTheme.typography.pxToRem(14),
                },
            },
            defaultProps: {
                size: 'small',
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 8,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'unset',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    elevation: 2,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: defaultTheme.typography.pxToRem(defaultTheme.shape.borderRadius / 2),
                    '&.rounded-circle': {
                        borderRadius: "50%",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                },
            },
        },
    },
});
export var themeLevel2 = createTheme(baseTheme, {
    palette: {
        background: {
            default: '#272727',
            paper: '#272727',
        },
        action: {
            active: '#e3e3e3',
            disabled: '#bababa',
        },
        divider: '#bababa',
    },
});
export var themeLevel3 = createTheme(baseTheme, {
    palette: {
        background: {
            default: '#323232',
            paper: '#323232',
        },
        action: {
            active: '#e4e4e4',
            disabled: '#bdbdbd',
        },
        divider: '#bdbdbd',
    },
});
export var themeLevel4 = createTheme(baseTheme, {
    palette: {
        background: {
            default: '#373737',
            paper: '#373737',
        },
        action: {
            active: '#e5e5e5',
            disabled: '#bfbfbf',
        },
        divider: '#bfbfbf',
    },
});
export var themeLevel5 = createTheme(baseTheme, {
    palette: {
        background: {
            default: '#424242',
            paper: '#424242',
        },
        success: createColor('#00BCA5de'),
        primary: {
            main: alpha('#5477B0', 0.68),
        },
    },
});
