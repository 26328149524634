var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel as TabPanelMUI } from '@mui/lab';
import { Box, Stack, Tab, styled } from '@mui/material';
import { useState } from 'react';
import { themeLevel3 } from '../config/theme';
var StyledTab = styled(Tab)(function (_a) {
    var customstyles = _a.customstyles;
    return (__assign({ minWidth: '8rem', '&.Mui-selected': {
            backgroundColor: themeLevel3.palette.background.default,
            color: themeLevel3.palette.text.primary,
        }, '&:not(.Mui-selected)': {
            backgroundColor: '#272727',
            color: '#E3E3E3',
        }, borderRadius: '0.75rem' }, customstyles));
});
var StyledTabList = styled(TabList)(function (_a) {
    var customstyles = _a.customstyles;
    return (__assign({ '.MuiTabs-scroller > .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        } }, customstyles));
});
export var TabPanel = styled(TabPanelMUI)(function () { return ({
    flex: 1,
    padding: 0,
}); });
export var TabComponent = function (_a) {
    var activeTab = _a.activeTab, defaultTab = _a.defaultTab, headerAction = _a.headerAction, tabs = _a.tabs, onChange = _a.onChange, children = _a.children, customTabListStyles = _a.customTabListStyles, customTabStyles = _a.customTabStyles, props = __rest(_a, ["activeTab", "defaultTab", "headerAction", "tabs", "onChange", "children", "customTabListStyles", "customTabStyles"]);
    var _b = useState(defaultTab !== null && defaultTab !== void 0 ? defaultTab : tabs[0].value), value = _b[0], setValue = _b[1];
    var activeValue = activeTab !== null && activeTab !== void 0 ? activeTab : value;
    var handleChangeTab = function (_, newValue) {
        if (activeValue !== newValue) {
            setValue(newValue);
            onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
        }
    };
    var renderTabContent = function () { return (_jsxs(TabContext, __assign({ value: activeValue }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", spacing: 4 }, { children: [_jsx(StyledTabList, __assign({ onChange: handleChangeTab, customstyles: customTabListStyles }, props, { children: tabs.map(function (tab) { return (_jsx(StyledTab, { label: tab.label, value: tab.value, customstyles: customTabStyles, icon: tab.icon && _jsx(FontAwesomeIcon, { size: "2x", icon: tab.icon }) }, tab.value)); }) })), headerAction] })), children] }))); };
    if (props.orientation === 'vertical') {
        return (_jsx(Box, __assign({ "data-testid": "vertical-tabs", sx: {
                flexGrow: 1,
                display: 'flex',
                height: 'auto',
            } }, { children: renderTabContent() })));
    }
    return _jsx(_Fragment, { children: renderTabContent() });
};
