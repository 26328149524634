import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, MobileStepper, ThemeProvider, styled } from '@mui/material';
import {
  AUDIT_EVENTS,
  CardActions,
  CardContent,
  CardHeader,
  ChartMenu,
  FallbackComponent,
  GA_EVENTS,
  SessionActive,
  checkNoData,
  themeLevel4,
  useAudit,
  useGA4,
  usePrintParam,
} from 'common-components';
import _ from 'lodash';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import SwipeableViews from 'react-swipeable-views';
import { LearningInProgress } from '../learninginprogress';
import { StyledItemCard } from '../styled';
import { RPETile } from './rpetile';
import { useActiveStepState, useResponseProfileExplorerAPI, useVEPExcelData, useViewModeState } from './selectors';
import { RPEViewMode } from './types';

const StepperIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.background.default,
  position: 'relative',
  bottom: theme.typography.pxToRem(4),
}));

const StepperButton = styled(Button)(() => ({
  minWidth: 0,
  padding: 0,
  '&:hover': {
    backgroundColor: 'unset !important',
  },
  '&.Mui-disabled > *': {
    backgroundColor: 'rgba(255, 255, 255, 0.38)',
  },
}));

const CircleBox = styled(Box)(() => ({
  position: 'relative',
  backgroundColor: 'rgba(255, 255, 255, 0.87)',
  borderRadius: '100%',
  width: '1.25rem',
  height: '1.25rem',
  padding: 0,
}));

const StyledFullScreenBox = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing(20),
  marginInline: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(3),
  flexBasis: 0,
  overflow: 'auto',
}));

const StyledModeButtonIcon = styled(IconButton)(({ theme }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.text.primary,
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginRight: theme.spacing(3),
}));

const FullScreenView = ({ children, printPDF }: { children: React.ReactNode; printPDF: boolean }) => (
  <StyledFullScreenBox data-testid="full-src-box">
    {children}
    {printPDF && <Box height="4rem" width="100%" />}
  </StyledFullScreenBox>
);

const SwipeableView = ({ children, activeStep }: { children: React.ReactNode; activeStep: number }) => (
  <SwipeableViews
    style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
    containerStyle={{ flex: 1, minHeight: 'fit-content' }}
    slideStyle={{ display: 'flex' }}
    axis="x"
    index={activeStep}
  >
    {children}
  </SwipeableViews>
);

const Wrapper: React.FC<PropsWithChildren<{ fullScreen: boolean; activeStep: number; printPDF: boolean }>> = ({
  fullScreen,
  children,
  activeStep,
  printPDF,
}) =>
  fullScreen ? (
    <FullScreenView printPDF={printPDF}>{children}</FullScreenView>
  ) : (
    <SwipeableView activeStep={activeStep}>{children}</SwipeableView>
  );

export const ResponseProfileExplorer = withSuspense()(({ fullScreen = false }: { fullScreen?: boolean }) => {
  const { t } = useTranslation('360analysis');
  const [responseProfiles, fetchRPEData, clearRPEData] = useResponseProfileExplorerAPI();
  const [excelData] = useVEPExcelData();
  const [, , putEvent] = useGA4();
  const [sendAuditEvent] = useAudit();
  const [viewMode, setViewMode] = useViewModeState();
  const [activeStep = 0, setActiveStep] = useActiveStepState();
  const [printPDF] = usePrintParam();

  const maxSteps = useMemo(() => responseProfiles.data?.results.length ?? 0, [responseProfiles.data]);
  const isNoData = useMemo(() => checkNoData(responseProfiles.data?.results), [responseProfiles.data?.results]);

  useEffect(() => {
    fetchRPEData();
    if (!printPDF) setActiveStep(0);
    return clearRPEData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRPEData, clearRPEData]);

  const handleChangeViewMode = () => {
    if (viewMode === RPEViewMode.Metrics) {
      setViewMode(RPEViewMode.Descriptive);
    } else {
      setViewMode(RPEViewMode.Metrics);
    }
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };
  return (
    <StyledItemCard>
      <CardHeader
        title={t('responseProfileExplorer.title')}
        titleTruncate
        action={
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems={'center'}>
            {fullScreen && (
              <StyledModeButtonIcon data-testid="btn-change-mode" onClick={handleChangeViewMode}>
                <FontAwesomeIcon
                  style={{ marginLeft: 0, width: '1rem', height: '1rem' }}
                  icon={viewMode === RPEViewMode.Metrics ? ['fal', 'person'] : ['fal', 'info']}
                />
              </StyledModeButtonIcon>
            )}
            <ChartMenu
              fsSession={SessionActive.ResponseProfileExplorer}
              excelData={excelData}
              onExcelExported={() => {
                sendAuditEvent({ action: AUDIT_EVENTS.campaign360.responseProfileExplorer.export });
                putEvent({
                  category: GA_EVENTS._360InsightsDashboard.__name,
                  action: GA_EVENTS._360InsightsDashboard.exportVisitorEngagementProfile,
                });
              }}
              isNoData={isNoData}
            />
          </Box>
        }
      />
      <CardContent
        sx={{
          position: 'relative',
          paddingBottom: 0,
          paddingInline: 0,
          flexBasis: 0,
          overflow: 'auto',
          '@media print': {
            overflow: 'visible',
          },
        }}
      >
        <ThemeProvider theme={themeLevel4}>
          <FallbackComponent requesting={responseProfiles.requesting ?? false}>
            {isNoData ? (
              <Box sx={{ width: '100%' }} paddingBottom={themeLevel4.spacing(4)}>
                <LearningInProgress />
              </Box>
            ) : (
              <Wrapper fullScreen={fullScreen} activeStep={activeStep} printPDF>
                {_.map(responseProfiles.data?.results, (data, index) => (
                  <RPETile key={index} data={data} />
                ))}
              </Wrapper>
            )}
          </FallbackComponent>
        </ThemeProvider>
      </CardContent>

      {!fullScreen && (
        <CardActions
          sx={{
            justifyContent: 'space-around',
            padding: 0,
            background: themeLevel4.palette.background.paper,
          }}
        >
          {maxSteps > 1 && (
            <MobileStepper
              sx={{ background: themeLevel4.palette.background.paper, fontSize: themeLevel4.typography.body3, gap: 3 }}
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <StepperButton data-testid="btn-next" onClick={handleNextStep} disabled={activeStep === maxSteps - 1}>
                  <CircleBox>
                    <StepperIcon fontSize={'0.75rem'} icon={['fal', 'chevron-right']} />
                  </CircleBox>
                </StepperButton>
              }
              backButton={
                <StepperButton data-testid="btn-back" onClick={handleBackStep} disabled={activeStep === 0}>
                  <CircleBox>
                    <StepperIcon fontSize={'0.75rem'} icon={['fal', 'chevron-left']} />
                  </CircleBox>
                </StepperButton>
              }
            />
          )}
        </CardActions>
      )}
    </StyledItemCard>
  );
});
