var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography, styled } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader } from '../common';
import { Site, SiteContextValue } from '../context';
import { useUserSessionAPI } from '../selectors';
import { APIKeyDialog } from './apiKeyModifier';
import { ConnectionBusinessTypeInfo } from './connectionBusinessTypeInfo';
import { CONNECTION_BUSINESS_TYPE } from './constants';
import { MailChimpDialog } from './mailChimpDialog';
import { MetaConnectorDialog } from './metaConnectorDialog';
import { TagDialogInfo } from './tagDialog';
import { XAdsConnectorDialog } from './xAdsConnectorDialog';
import { GoogleAdsConnectorDialog } from './googleAdsConnectorDialog';
var StyledCard = styled(Card)(function () { return ({
    flex: 1,
    boxShadow: '0rem 0rem 2rem 2px rgba(0, 0, 0, 0.25)',
}); });
export var EmptyCard = function (_a) {
    var text = _a.text;
    return (_jsx(StyledCard, __assign({ style: { minHeight: '8.375rem' } }, { children: _jsx(CardContent, { children: _jsx(Box, __assign({ sx: { margin: 'auto' } }, { children: _jsx(Typography, __assign({ variant: "body2", sx: { wordBreak: 'break-word', color: 'success.main' } }, { children: text })) })) }) })));
};
export var ConnectionCard = function (_a) {
    var _b, _c, _d;
    var connectionTypeData = _a.connectionTypeData, orgId = _a.orgId, externalError = _a.externalError, disable = _a.disable, color = _a.color, onSaveConnection = _a.onSaveConnection, requesting = _a.requesting;
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _e = useState(false), showDialog = _e[0], setShowDialog = _e[1];
    var _f = useState(externalError), error = _f[0], setError = _f[1];
    var _g = useContext(SiteContextValue).site, site = _g === void 0 ? Site.Portal : _g;
    var userSessionData = useUserSessionAPI()[0];
    var connectionBusinessType = (_b = CONNECTION_BUSINESS_TYPE[connectionTypeData.source]) !== null && _b !== void 0 ? _b : CONNECTION_BUSINESS_TYPE.other;
    useEffect(function () {
        setError(externalError);
    }, [externalError]);
    var disableOauth2Connections = useMemo(function () { var _a; return site === Site.Admin || (site === Site.Portal && ((_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.superUser)); }, [site, userSessionData]);
    return (_jsxs(_Fragment, { children: [_jsxs(StyledCard, { children: [_jsx(CardHeader, { title: _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        lineHeight: '0.5',
                                        zIndex: function (themes) { return themes.zIndex.drawer + 1; },
                                        position: 'relative',
                                    }, color: disable ? 'text.disabled' : color }, { children: connectionTypeData.displayName })), _jsxs(Typography, __assign({ variant: "caption", sx: { wordBreak: 'break-word', fontSize: '0.75rem', fontWeight: 'fontWeightRegular' }, color: color }, { children: [t('connection.lastRefresh'), ":", ' ', connectionTypeData.connections
                                            ? moment(connectionTypeData.connections[0].updatedDate).format('MM/DD/YYYY')
                                            : 'N/A'] }))] }), action: _jsxs(_Fragment, { children: [_jsx(ConnectionBusinessTypeInfo, { connectionBusinessType: connectionBusinessType }), _jsx(IconButton, __assign({ disabled: disable, "data-testid": "config-button", onClick: function () { return setShowDialog(true); } }, { children: _jsx(FontAwesomeIcon, { icon: ['fal', 'gear'], color: color }) }))] }) }), _jsx(CardContent, { children: _jsx(Box, { children: _jsx(Typography, __assign({ variant: "body2", sx: { fontWeight: 'fontWeightLight', fontSize: '1rem', color: color } }, { children: connectionTypeData.description })) }) })] }), connectionTypeData.type === 'Tag' && (_jsx(TagDialogInfo, { open: showDialog, onCancel: function () { return setShowDialog(false); }, connectionData: connectionTypeData, connectionBusinessType: connectionBusinessType })), connectionTypeData.type === 'API Key' && (_jsx(APIKeyDialog, { open: showDialog, onCancel: function () {
                    setShowDialog(false);
                    setError(null);
                }, connectionData: connectionTypeData.connections || [], orgId: orgId, connectionTypeId: connectionTypeData.id, onSubmit: onSaveConnection, externalError: error, requesting: requesting, provider: connectionTypeData.displayName, enableAccessConfig: connectionTypeData.enableAccessConfig, connectionBusinessType: connectionBusinessType })), connectionTypeData.type === 'Access Token' && (_jsx(MailChimpDialog, { open: showDialog, onCancel: function () { return setShowDialog(false); }, connectionData: connectionTypeData.connections || [], orgId: orgId, connectionTypeId: connectionTypeData.id, requesting: requesting, disableOauth2: !!disableOauth2Connections, connectionBusinessType: connectionBusinessType })), connectionTypeData.type === 'Meta Access' && (_jsx(MetaConnectorDialog, { open: showDialog, onCancel: function () {
                    setShowDialog(false);
                    setError(null);
                }, connectionData: connectionTypeData.connections || [], orgId: orgId, connectionTypeId: connectionTypeData.id, onSubmit: onSaveConnection, externalError: error, requesting: requesting, provider: connectionTypeData.displayName, connectionBusinessType: connectionBusinessType })), connectionTypeData.type === 'X Ads Access' && (_jsx(XAdsConnectorDialog, { open: showDialog, onCancel: function () {
                    setShowDialog(false);
                    setError(null);
                }, connectionData: (_c = connectionTypeData.connections) === null || _c === void 0 ? void 0 : _c[0], orgId: orgId, connectionTypeId: connectionTypeData.id, onSubmit: onSaveConnection, externalError: error, requesting: requesting, provider: connectionTypeData.displayName, connectionBusinessType: connectionBusinessType })), connectionTypeData.type === 'Google Ads Access' && (_jsx(GoogleAdsConnectorDialog, { open: showDialog, onCancel: function () {
                    setShowDialog(false);
                    setError(null);
                }, connectionData: (_d = connectionTypeData.connections) === null || _d === void 0 ? void 0 : _d[0], orgId: orgId, connectionTypeId: connectionTypeData.id, onSubmit: onSaveConnection, requesting: requesting, connectionBusinessType: connectionBusinessType, provider: connectionTypeData.displayName, externalError: error }))] }));
};
export * from './tag';
export * from './connectionBusinessTypeInfo';
