export var GA_EVENTS = {
    auth: {
        __name: 'Authenication',
        login: 'Login',
        logout: 'Logout',
    },
    selectPlan: {
        __name: 'Select Plan',
        view: 'View Select Plan',
        subscribe: 'Subscribe',
    },
    welcomePage: {
        __name: 'Welcome Page',
        view: 'View Welcome Page',
    },
    faq: {
        __name: 'FAQ',
        view: 'View FAQ',
    },
    websiteResolutionDashboard: {
        __name: 'Prospect 360 Dashboard',
        view: 'View Prospect 360 Dashboard',
        exportPageToPDF: 'Export Prospect 360 to PDF',
        exportEnrichedConsumerDataToExcel: 'Export Consumer Data to Excel',
        exportEnrichedBusinessContactToExcel: 'Export Business Contact to Excel',
        viewEnrichedConsumerDataB2cTraffic: 'View Consumer Data (B2C) Traffic',
        viewEnrichedBusinessContactB2bData: 'View Business Contact (B2B) Data',
        b2cTrafficInsightChangeGeoToRegions: 'B2C Traffic - Change Geo to Regions',
        b2cTrafficInsightChangeGeoToMap: 'B2C Traffic - Change Geo to Map',
        b2bTrafficInsightChangeGeoToRegions: 'B2B Traffic - Change Geo to Regions',
        b2bTrafficInsightChangeGeoToMap: 'B2B Traffic - Change Geo to Map',
        exportProspectsToExcel: 'Export Prospects to Excel',
        exportEngagementToExcel: 'Export Engagement to Excel',
    },
    _360InsightsDashboard: {
        __name: '360 Insights Dashboard',
        view: 'View 360 Insights Dashboard',
        changeCampaignFilter: 'Change Campaign Filter',
        changeTacticsFilter: 'Change Tactics Filter',
        exportPagetoPDF: 'Export 360 Insights Dashboard to PDF',
        changeLocationDetailMetric: 'Change Location Detail Metric',
        changeChartDisplayForDemographicsComponent: 'Change Chart display for Demo',
        exportStatSummaryToExcel: 'Export Stat Summary to Excel',
        exportLocationDetailToExcel: 'Export Location Detail to Excel',
        exportDemographicsToExcel: 'Export Demographics to Excel',
        exportVisitorEngagementProfile: 'Export Visitor Engagement Profile to Excel',
    },
    accountManagement: {
        __name: 'Account Management',
        view: 'View Account Management',
        addUser: 'Add User',
        editUser: 'Edit User',
        deleteUser: 'Delete User',
        managePlan: 'Manage Plan',
        editOrganization: 'Edit Organization',
    },
    audienceExport: {
        __name: 'Audience Export',
        view: 'View Audience Export',
        exportPageToPDF: 'Export Audience Export to PDF',
        exportAudienceToExcel: 'Export Audience to Excel',
    },
    audience: {
        __name: 'Audience',
        view: 'View Audience',
        addAudience: 'Add Audience',
        editAudience: 'Edit Audience',
        deleteAudience: 'Delete Audience',
        downloadAudience: 'Download Audience',
        copyAudience: 'Copy Audience',
    },
};
