var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import { render } from '@testing-library/react';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import promiseMiddleware from 'redux-promise';
import { baseTheme } from '../config/theme';
import { reducer } from '../state';
var defaultStore = configureStore({
    reducer: reducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(promiseMiddleware);
    },
    devTools: false,
});
export var StoreWrapperComponent = function (_a) {
    var children = _a.children;
    return (_jsx(Provider, __assign({ store: defaultStore }, { children: children })));
};
export var renderWithProviders = function (ui, _a) {
    if (_a === void 0) { _a = {}; }
    var 
    // Automatically create a store instance if no store was passed in
    _b = _a.store, 
    // Automatically create a store instance if no store was passed in
    store = _b === void 0 ? defaultStore : _b, renderOptions = __rest(_a, ["store"]);
    var WrapperComponent = function (_a) {
        var children = _a.children;
        return (_jsx(Provider, __assign({ store: store }, { children: _jsx(BrowserRouter, { children: _jsx(ThemeProvider, __assign({ theme: baseTheme }, { children: children })) }) })));
    };
    // Return an object with the store and all of RTL's query functions
    return __assign({ store: store }, render(ui, __assign({ wrapper: WrapperComponent }, renderOptions)));
};
export var mockComponentWithTestId = function (testId) { return _jsx("div", { "data-testid": testId }); };
export var MockedChartMenu = function (props) {
    var _a;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    return (_jsxs("div", { children: [_jsx("button", __assign({ "data-testid": "menu-button", onClick: function () { return setShow(true); } }, { children: "menu button" })), show && (_jsxs("div", __assign({ role: "menu" }, { children: [(_a = props.prefixItems) === null || _a === void 0 ? void 0 : _a.map(function (v) {
                        return !v.hidden && (_jsx("button", __assign({ "data-testid": "item-button-".concat(v.name), onClick: function () {
                                v.action();
                                setShow(false);
                            } }, { children: v.name }), v.name));
                    }), _jsx("button", __assign({ onClick: props.onExcelExported }, { children: "exported" }))] })))] }));
};
