var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CardMedia, Grid, Link, Stack, ThemeProvider, Typography, styled } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { Card, CardActions, CardContent, CardHeader, FallbackComponent, MessageBox, ParagraphTruncateTooltip, } from '../common';
import { themeLevel3 } from '../config/theme';
import { AUDIT_EVENTS, GA_EVENTS } from '../constants';
import { Site, SiteContextValue } from '../context';
import { FullscreenContainer } from '../fullscreen';
import { useAudit, useGA4 } from '../hooks';
import { FeatureList, usePaymentConfigAPI, useSystemParamAPI, useUserSessionAPI } from '../selectors';
import { formatValue, important } from '../utils';
import { Organization } from './organization';
import { useOrganizationDetailAPI, usePlanDetail } from './selectors';
import { TabsUsersAndConnections } from './tabsusersandconnections';
var StyledCardMedia = styled(CardMedia)(function (_a) {
    var theme = _a.theme;
    return ({
        marginRight: theme.spacing(4),
        padding: theme.spacing(4),
        width: 'auto',
        height: '101px',
        borderRadius: theme.typography.pxToRem(theme.shape.borderRadius),
        border: "1px solid ".concat(theme.palette.text.primary),
    });
});
var StyledIconError = styled(FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        marginRight: theme.spacing(4),
        padding: theme.spacing(4),
        width: 'auto',
        height: '101px',
        borderRadius: theme.typography.pxToRem(theme.shape.borderRadius),
        border: "1px solid ".concat(theme.palette.text.primary),
        boxSizing: important('border-box'),
    });
});
var PlanCard = withSuspense()(function () {
    var _a, _b, _c;
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var planDetail = usePlanDetail()[0];
    var orgDetail = useOrganizationDetailAPI()[0];
    var quantity = useMemo(function () {
        var _a, _b, _c;
        var tierQuantity = ((_b = (_a = planDetail.data) === null || _a === void 0 ? void 0 : _a.tier) === null || _b === void 0 ? void 0 : _b.to) ? " - ".concat(formatValue(planDetail.data.tier.to)) : '+';
        return !((_c = planDetail.data) === null || _c === void 0 ? void 0 : _c.tier) ? t('unlimited') : "".concat(formatValue(planDetail.data.tier.from)).concat(tierQuantity);
    }, [(_a = planDetail.data) === null || _a === void 0 ? void 0 : _a.tier, t]);
    if (planDetail.requesting)
        return _jsx(FallbackComponent, {});
    if (planDetail.error)
        return _jsx(MessageBox, { severity: "error", messages: t('notFoundPlan') });
    return (_jsx(Stack, __assign({ flex: 1, direction: "row", display: "flex" }, { children: planDetail.data ? (_jsxs(_Fragment, { children: [_jsx(StyledCardMedia, { image: planDetail.data.product.image, component: "img" }), _jsxs(Stack, __assign({ display: "flex", justifyContent: "space-evenly", flex: 1 }, { children: [_jsx(ParagraphTruncateTooltip, { maxLine: 1, variant: "subtitle1", content: planDetail.data.product.name }), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: t('plan.quantity', { quantity: quantity }) })), _jsx(Typography, __assign({ variant: "body2" }, { children: t(((_c = (_b = orgDetail.data) === null || _b === void 0 ? void 0 : _b.plan) === null || _c === void 0 ? void 0 : _c.isTrial) ? 'plan.trialRenew' : 'plan.renew', {
                                renew: moment.unix(planDetail.data.renewal).format('MM/DD/YYYY'),
                            }) }))] }))] })) : (_jsxs(_Fragment, { children: [_jsx(StyledIconError, { icon: ['fal', 'circle-exclamation'], color: "#9B5094" }), _jsxs(Stack, __assign({ display: "flex", justifyContent: "space-evenly", flex: 1 }, { children: [_jsx(ParagraphTruncateTooltip, { maxLine: 1, variant: "subtitle1", content: t('text.notProvided', { ns: 'translation' }) }), _jsx(Typography, __assign({ variant: "body2" }, { children: t('plan.invalidSubscription') }))] }))] })) })));
});
var ManagePlanButton = withSuspense()(function () {
    var configData = usePaymentConfigAPI()[0];
    var userSessionData = useUserSessionAPI()[0];
    var t = useTranslation('accountmanagement').t;
    var _a = useGA4(), putEvent = _a[2];
    var sendAuditEvent = useAudit()[0];
    var openCustomerPortalLink = function () {
        if (configData.data && userSessionData.data) {
            putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.managePlan });
            sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.plan.manage });
            window.open("".concat(configData.data.paymentCustomerPortalLink, "?prefilled_email=").concat(userSessionData.data.email));
        }
    };
    return (_jsx(Link, __assign({ color: "success.main", fontSize: "inherit", underline: "none", component: "button", disabled: !configData.data || !userSessionData.data, onClick: openCustomerPortalLink }, { children: t('button.managePlan') })));
});
var StripeManageButton = withSuspense()(function () {
    var t = useTranslation('accountmanagement').t;
    var configData = usePaymentConfigAPI()[0];
    var orgDetail = useOrganizationDetailAPI()[0];
    var openStripeManageLink = function () {
        if (configData.data && orgDetail.data) {
            window.open("".concat(configData.data.stripeManagementConsoleLink, "/").concat(orgDetail.data.paymentCustomer));
        }
    };
    return (_jsx(Link, __assign({ color: "success.main", fontSize: "inherit", underline: "none", component: "button", disabled: !configData.data || !orgDetail.data, onClick: openStripeManageLink }, { children: t('button.stripeManagement') })));
});
var ManageStripeButton = (_a = {},
    _a[Site.Portal] = ManagePlanButton,
    _a[Site.Admin] = StripeManageButton,
    _a);
var Plan = withSuspense()(function () {
    var t = useTranslation('accountmanagement').t;
    var _a = usePlanDetail(), fetchPlanDetail = _a[1], clearPlanDetail = _a[2];
    var _b = useContext(SiteContextValue).site, site = _b === void 0 ? Site.Portal : _b;
    var ManageButtonComponent = ManageStripeButton[site];
    useEffect(function () {
        fetchPlanDetail();
        return function () {
            clearPlanDetail();
        };
    }, [fetchPlanDetail, clearPlanDetail]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { title: t('planTitle') }), _jsx(CardContent, { children: _jsx(PlanCard, {}) }), _jsx(CardActions, { children: _jsx(ManageButtonComponent, {}) })] }));
});
export var useGetPortalUri = function (featureFlags) {
    var _a, _b;
    var systemParam = useSystemParamAPI()[0];
    var hasFlagKrateov1 = useMemo(function () { return _.some(featureFlags, { flagKey: FeatureList.KrateoOriginalVersion }); }, [featureFlags]);
    return hasFlagKrateov1 ? (_a = systemParam.data) === null || _a === void 0 ? void 0 : _a.uiUri : (_b = systemParam.data) === null || _b === void 0 ? void 0 : _b.audienceUiUri;
};
var AccountAndOrganization = function () {
    var _a;
    var t = useTranslation(['organization']).t;
    var _b = useParams().orgId, orgId = _b === void 0 ? '' : _b;
    var _c = useContext(SiteContextValue).site, site = _c === void 0 ? Site.Portal : _c;
    var orgDetail = useOrganizationDetailAPI()[0];
    var portalUri = useGetPortalUri((_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.featureFlags);
    return (_jsxs(Stack, __assign({ flex: 1, display: 'flex', spacing: 2.5 }, { children: [_jsx(Box, __assign({ height: '3rem', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }, { children: site === Site.Admin && (_jsx(Button, __assign({ variant: "outlined", color: "inherit", startIcon: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: ['fal', 'person-to-portal'] }), href: "".concat(portalUri, "?sessionData=").concat(window.btoa(JSON.stringify({ orgId: orgId }))), target: "_blank", rel: "noopener" }, { children: t('menu.viewCustomerPortal') }))) })), _jsxs(Stack, __assign({ flex: 1, spacing: 4 }, { children: [_jsx(Plan, {}), _jsx(Organization, {})] }))] })));
};
export var PAccountManagement = withSuspense()(function () {
    var _a = useGA4(), putEvent = _a[2];
    useEffect(function () { return putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.view }); }, [putEvent]);
    return (_jsx(ThemeProvider, __assign({ theme: themeLevel3 }, { children: _jsx(Stack, __assign({ flex: 1, spacing: 2.75 }, { children: _jsx(FullscreenContainer, { children: _jsxs(Grid, __assign({ flex: 1, container: true, spacing: 5.5 }, { children: [_jsx(Grid, __assign({ container: true, item: true, xs: 8 }, { children: _jsx(TabsUsersAndConnections, {}) })), _jsx(Grid, __assign({ container: true, item: true, xs: 4 }, { children: _jsx(AccountAndOrganization, {}) }))] })) }) })) })));
});
export var AccountManagementPageParam = {
    DEFAULT_TAB: 'defaultTab',
};
export * from './usersmanagement';
