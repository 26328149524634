import axios from 'axios';
import { useContext, useEffect, useRef } from 'react';
import { LOGOUT_URL } from '../../constants';
import { SiteContextValue } from '../../context';
export var useAxios = function () {
    var axiosInstance = useRef();
    var site = useContext(SiteContextValue).site;
    useEffect(function () {
        var _a, _b;
        axiosInstance.current = axios.create({
            withCredentials: true,
        });
        (_a = axiosInstance.current) === null || _a === void 0 ? void 0 : _a.interceptors.request.use(function (request) {
            if (site) {
                request.headers = Object.assign(request.headers || {}, { site: site });
            }
            if (window.orgId) {
                request.headers = Object.assign(request.headers || {}, { orgid: window.orgId });
            }
            return request;
        });
        (_b = axiosInstance.current) === null || _b === void 0 ? void 0 : _b.interceptors.response.use(function (response) { return response; }, function (error) {
            var _a;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && !window.isLogout) {
                window.open("".concat(LOGOUT_URL, "?state=").concat(encodeURIComponent(window.btoa(window.location.href))), '_self');
            }
            throw error;
        });
        return function () {
            axiosInstance.current = undefined;
        };
    }, [site]);
    return axiosInstance;
};
