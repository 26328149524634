import '@fontsource/josefin-sans';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Middleware, configureStore } from '@reduxjs/toolkit';
import { MarAction, MarReducer, baseTheme, configI18next } from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storageSession from 'redux-persist/lib/storage/session';
import promiseMiddleware from 'redux-promise';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import { reducer } from './state';

configI18next();

const setTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: any) => {
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState) => {
    const dateRange = _.cloneDeep(outboundState?.dateRange ?? {});
    Object.keys(dateRange).forEach((key) => {
      const [fromDateString, toDateString] = dateRange[key];
      dateRange[key] = [moment(fromDateString), moment(toDateString)];
    });
    return { ...outboundState, dateRange };
  },
  { whitelist: ['app'] }
);

const rootReducer = combineReducers({
  ...reducer,
});
const rootPersistConfig = {
  key: 'ms-portal-state',
  storage: storageSession,
  blacklist: ['api'],
  transforms: [setTransform],
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore<MarReducer, MarAction, Middleware[]>({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(promiseMiddleware),
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={baseTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
